<template>
  <div
    class="history-bots d-flex justify-center"
    :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-10'"
  >
    <div :class="$vuetify.breakpoint.smAndDown ? 'w-100' : 'w-80'">
      <Bots
        :botHeader="'Select and Display chat histories'"
        :class="$vuetify.breakpoint.smAndDown ? 'd-none' : ''"
      />

      <v-divider
        class="my-8"
        :class="$vuetify.breakpoint.smAndDown ? 'd-none' : ''"
      ></v-divider>
      <LoadingComponent v-if="isLoading" />

      <v-container
        v-if="!isLoading && userBotHistory && userBotHistory.length > 0"
      >
        <v-row>
          <v-col cols="12" md="4" lg="4" sm="12">
            <h4 class="mb-4">Select your conversation below</h4>

            <div
              style="height: 50vh !important; overflow-y: auto"
              class="chat-messages pr-3"
            >
              <!-- loop of history on selected bot -->
              <!-- select last history as active by default -->
              <v-card
                v-for="(item, i) in userBotHistory"
                :key="i"
                outlined
                class="mb-2"
                style="border: 1px solid"
                :style="{
                  'border-color': isActive(item) ? '#4285f3' : '#E4E4E7',
                }"
                @click="openHistoryItem(item)"
              >
                <div class="d-flex justify-space-between">
                  <div>
                    <div class="align-center justify-space-between pr-2">
                      <v-card-title
                        class="pa-2 pb-0 pl-3 pt-3"
                        style="font-size: 11px; line-height: 1.2em"
                      >
                        {{ item.updated_at | formattedDate }}</v-card-title
                      >
                    </div>
                    <v-card-text class="font-weight-medium pt-0 px-3"
                      ><b style="color: #4285f3">User:</b> {{ item.title }}
                    </v-card-text>
                  </div>
                  <div>
                    <v-switch
                      v-model="item.toggle"
                      @click.stop="toggleConversation(item)"
                      dense
                    ></v-switch>
                  </div>
                </div>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12" md="8" lg="8" sm="12">
            <div class="d-flex align-start justify-space-between mb-4">
              <h4 class="">Chat conversation</h4>
              <v-btn outlined small color="green" style="visibility: hidden"
                >Download Messages</v-btn
              >
            </div>
            <div
              style="height: 50vh !important; overflow-y: auto"
              class="chat-messages pr-3 chat-container"
              id="chatContainer"
              ref="chatContainer"
            >
              <LoadingComponent v-if="isLoadingMessages" />
              <!-- get user and ai bubble here -->
              <div
                v-if="
                  !isLoading &&
                  !isLoadingMessages &&
                  userBotHistoryMessages &&
                  userBotHistoryMessages.length > 0
                "
              >
                <div class="d-flex justify-center pt-3">
                  <p v-if="nextPage" class="mr-1">
                    <v-btn
                      outlined
                      color="primary"
                      :loading="isLoadMoreLoading"
                      small
                      @click="getNextPageMessage()"
                    >
                      Load More
                    </v-btn>
                  </p>
                </div>
                <div v-for="(message, i) in userBotHistoryMessages" :key="i">
                  <myBubble
                    v-if="message.role == 'user'"
                    :text="message.content"
                    :activeBot="activeBot"
                    class="mx-4"
                  />
                  <aiBubble
                    v-else
                    :text="message.content"
                    :activeBot="activeBot"
                    class="mx-4"
                  />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <div
        v-if="!isLoading && userBotHistory.length <= 0"
        class="w-100 d-flex justify-center align-center"
      >
        <div class="text-center">
          <p class="mb-2 font-weight-bold">No data available.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Bots from "@/components/bot_profile/bots.vue";
import LoadingComponent from "@/components/helpers/Loading";
import myBubble from "@/components/chat/myBubble";
import aiBubble from "@/components/chat/aiBubble";
// import router from "@/router";
import Utils from "@/helpers/utils";

export default {
  name: "trainings",
  components: {
    Bots,
    LoadingComponent,
    myBubble,
    aiBubble,
  },
  data() {
    return {
      isLoading: false,
      baseBot: {},
      botTemplate: null,
      userBotHistory: [],
      isLoadingMessages: false,
      userBotHistoryMessages: [],
      toggleValue: false,
      isLoadMoreLoading: false,
      nextPage: false,
    };
  },
  filters: {
    formattedDate(val) {
      return moment(new Date(val)).format("D MMM YYYY, h:mmA");
    },
  },
  watch: {
    // currentUser: {
    //   immediate: true,
    //   handler(n) {
    //     if (_.isEmpty(n)) {
    //       return;
    //     }

    //     if (this.paramBotId == null) {
    //       this.setActiveBot(this.bots[0]);
    //     }

    //     this.loadUserBotHistory();
    //   },
    // },
    paramBotId: {
      immediate: true,
      handler(v) {
        if (_.isEmpty(v)) {
          return;
        }
        this.loadUserBotHistory();
      },
    },
  },
  mounted() {
    if (this.paramBotId == null) {
      this.setActiveBot(this.bots[0]);
      // this.setContact({
      //   emails: this.bots[0]?.emails,
      //   mobile_numbers: this.bots[0]?.mobile_numbers,
      // });
      this.loadUserBotHistory();
    }
    this.initHistory(this.paramBotId);
  },

  methods: {
    ...mapMutations("bots", ["setActiveBot", "setActiveBot"]),
    ...mapActions("bots", [
      "getUserBotHistory",
      "getUserBotHistoryMessages",
      "updateConversationToggle",
      "getNextPage",
    ]),
    async initHistory(id) {
      try {
        this.isLoading = true;
        if (!id) return;

        await this.getUserBotHistory(id).then(
          (res) => {
            this.userBotHistory = res.data;
            this.openHistoryItem(res.data[0]);
          },
          (err) => {
            this.isLoading = false;
          }
        );
      } finally {
        this.isLoading = false;
      }
    },
    loadUserBotHistory: _.debounce(async function () {
      let botTemplate = _.cloneDeep(this.activeBot);
      try {
        if (!botTemplate?.id) return;

        this.isLoading = true;
        await this.getUserBotHistory(botTemplate.id).then(
          (res) => {
            this.userBotHistory = res.data;
            this.openHistoryItem(res.data[0]);
          },
          (err) => {
            this.isLoading = false;
          }
        );
      } finally {
        this.isLoading = false;
      }
    }, 10),
    async openHistoryItem(item) {
      try {
        this.isLoadingMessages = true;
        await this.getUserBotHistoryMessages({
          id: item.chatbot_data_id,
          convoId: item.id,
        }).then(
          (res) => {
            if (_.get(res, "data", null)) {
              this.userBotHistoryMessages = res.data.data.reverse();
              this.nextPage = res.data.next_page_url;
              this.scrollToBottom();
            }
            this.$router.replace({
              path:
                "/dashboard/history/" +
                item.chatbot_data_id +
                "/messages/" +
                item.id,
            });
            this.isLoadingMessages = false;
          },
          (err) => {
            this.isLoadingMessages = false;
          }
        );
      } catch (err) {
        this.isLoadingMessages = false;
      }
    },
    async getNextPageMessage() {
      try {
        this.isLoadMoreLoading = true;

        const chatContainer = this.$refs.chatContainer;
        const currentScrollHeight = chatContainer.scrollHeight;
        const currentScrollTop = chatContainer.scrollTop;

        await this.getNextPage(this.nextPage).then(
          (res) => {
            if (_.get(res, "data", null)) {
              this.userBotHistoryMessages = [
                ...res.data.data.reverse(),
                ...this.userBotHistoryMessages,
              ];
              this.nextPage = res.data.next_page_url;
              this.$nextTick(() => {
                const newScrollHeight = chatContainer.scrollHeight;
                chatContainer.scrollTop =
                  newScrollHeight - currentScrollHeight + currentScrollTop;
              });
            }
            this.isLoadMoreLoading = false;
          },
          (err) => {
            this.isLoadMoreLoading = false;
          }
        );
      } catch (err) {
        this.isLoadMoreLoading = false;
      }
    },
    isActive(item) {
      if (_.get(this.$router.currentRoute, "params.messagesId") == item.id) {
        return true;
      } else {
        return false;
      }
    },
    async toggleConversation(form) {
      try {
        await this.updateConversationToggle({
          data: form,
        })
          .then(
            (res) => {
              if (res.data.toggle) {
                globalMessageHandler("Conversation is enabled.");
              } else {
                globalMessageHandler("Conversation is disabled.");
              }
            },
            (err) => {
              globalErrorHandler(err, "Error updating history!");
            }
          )
          .finally(() => {});
      } catch (error) {
        globalErrorHandler(error, "Unable to toggle history.");
      } finally {
      }
    },
    scrollToBottom() {
      setTimeout(() => {
        const chatContainer = this.$refs.chatContainer;
        Utils.scrollTo(
          {
            top: chatContainer && chatContainer.scrollHeight + 1000,
            behavior: "smooth",
          },
          "chatContainer"
        );
        setTimeout(() => {
          chatContainer.scrollTop = chatContainer.scrollHeight + 500;
        }, 300);
      }, 200);
    },
  },

  computed: {
    ...mapGetters("bots", ["bots", "activeBot"]),
    ...mapGetters("auth", ["currentUser"]),
    getCurrentRoute() {
      return this.$route.name;
    },
    paramBotId() {
      return this.$route.params.botId; //|| this.getCurrentRoute;
    },
    messagesId() {
      return this.$route.params.messagesId; //|| this.getCurrentRoute;
    },
    isReady() {
      return {
        user: this.currentUser,
        route: this.$route,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
/* Custom thin scrollbar */
.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
</style>
